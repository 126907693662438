// Profile.js
import React from "react";
import profileImgSrc from "../assets/프로필.png";
import styled from "styled-components";
import { motion } from "framer-motion";

const Profile = ({ setIsAnimationComplete }) => {
  return (
    <FadeInOut
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ duration: 2, delay: 2 }}
      exit={{ opacity: 0, transition: { duration: 2 } }}
      onAnimationComplete={() => setIsAnimationComplete(true)}
    >
      <Img src={profileImgSrc} alt="Profile" />
    </FadeInOut>
  );
};

const FadeInOut = styled(motion.div)`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

const Img = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

export default Profile;
