// Main.js
import React, { useState } from "react";
import styled from "styled-components";
import Content from "./Content";
import Profile from "./Profile";
import { AnimatePresence } from "framer-motion";

const Main = () => {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  return (
    <Container>
      <AnimatePresence>
        {!isAnimationComplete && (
          <Profile setIsAnimationComplete={setIsAnimationComplete} />
        )}
      </AnimatePresence>
      <Content />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden; // 프로필 애니메이션 시 스크롤 숨김
`;

export default Main;
