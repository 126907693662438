import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Content() {
  const pageList = [
    {
      header: "연혁",
      body: [
        { title: "2002.11", body: "태평초등학교 입학" },
        { title: "2009.03", body: "태평초등학교 졸업" },
        { title: "2009.03", body: "태평중학교 입학" },
        { title: "2012.02", body: "태평중학교 졸업" },
        { title: "2012.03", body: "대전대성고등학교 입학" },
        { title: "2015.02", body: "대전대성고등학교 졸업" },
        { title: "2015.03", body: "순천향대학교 입학" },
        { title: "2023.05", body: "HYEOB.COOPERATION 설립" },
        { title: "2023.05", body: "HYEOB.COOPERATION 대표이사 등재" },
      ],
    },
    {
      header: "신체능력",
      body: [
        "179.7cm",
        "62kg",
        "태권도 2단",
        "유도 2단",
        "주짓수 경험",
        "복싱 경험",
        "해동검도 크루",
        "레슬링 경험(해보고 싶음)",
        "크로스핏 경험(해보고 싶음)",
        "Weight training",
        "Squat 110kg",
        "BenchPress 70kg",
        "DeadLift 120kg",
        "Total 300kg",
      ],
    },
    {
      header: "신체능력",
      body: [
        "대전 대표 플로어볼 대회 출전",
        "구 대표 육상 대회 출전 경험 多",
        "구 대표 킨볼 대회 출전",
        "리듬줄넘기부 공연 경험 多",
        "초등학교 제기부 탈락",
        "천재교육 초등체육교과서 등재(2015 개정판)",
        "풀업 최대 17회",
        "머슬업 가능",
        "서전트 점프 최대 3m",
        "한강시민 마라톤",
        "온에어런 마라톤",
        "대전 울트라 마라톤",
        "마라톤 출전 경험 多(메달 보유)",
        "슬릭백 기술 보유",
        "이용협배 한발두발 대회 초대 우승자",
      ],
    },
    { header: "식성", body: [
      "확신의 한식파",
      "제일 좋아하는 음식은 돼지고기 김치찜",
      "라면 극호",
      "한식>>>중식=양식=일식",
      "치킨/피자/햄버거 비선호",
      "과일X(사과, 귤, 오렌지 제외)",
      "야채, 채소 비선호",
      "CU닭가슴살 선호",
      "사이다 vs 콜라 -> 사이다",
      "코카콜라 vs 펩시 -> 저렴한 것",
      "순대에 소금 vs 쌈장 -> 소금",
      "순대만/내장포함 -> 내장 포함",
      "소고기/돼지고기 -> 돼지고기",
    ] },
    { header: "식성", body: [

      "민초파 지지",
      "진라면에 대한 중립입장 표명",
      "마라탕, 탕후루 불호",
      "로제 극 불호",
    ] },
    { header: "특이사항", body: [
      "ESTP",
      "찐혐",
      "뚱혐",
      "대부분의 상황을 본인 기준에 맞춤",
      "답답한 상황을 상당히 싫어함",
      "대화의 맥락을 깨는 사람을 싫어함",
      "장난이 많음",
      "장난을 대수롭지 않게 넘김",
      "화를 잘 안냄 => false",
      "패드립, 가족 건들면 터짐",
      "원하지 않는 상황에서 굳이 참견 받는 것을 싫어함",
      "자존심을 건드리면 가만히 있지 않음",
    ] },
    { header: "이상형", body: [
      "이용협보다 키와 덩치가 작은 사람",
      "다소 마른 사람",
      "건강한 생각, 가치관을 가지고 있는 사람",
      "본인의 목표가 뚜렷한 사람",
      "서로에게 너무 큰 간섭을 하지 않는 사람",
      "연락이 잘 되는 사람",
      "담배 안 피는 사람(중요)",
      "술을 좋아하지 않는 사람",
      "본인 관리를 잘 하는 사람",
      "별 거 아닌 일. 정답이 뻔히 보이는 일로 칭얼거리지 않는 사람",
      "본인 생각대로 되지 않는다고 남들에게 짜증내지 않는 사람",
      "누가봐도 본인 잘못인데 인정 안 하고 무지성 공감만을 원하지 않는 사람",
      "기능적 사고가 가능한 여자",

    ] },
    { header: "이상형", body: [
      "보여주기식 허례허식에 찌들지 않는 여자",
      "친구 남자친구가 해준 생일선물과 비교하며 나를 까내리지 않는 여자",
      "하루에 인스타그램 스토리를 5개 이상 올려 본인의 일거수 일투족을 남자친구가 아닌 그 누구나 알 수 있게하여 \"이 새끼 애정결핍인가?\"를 떠올리지 않게 하는 여자",
      "몸에 허례허식이 가득찬 문신, 타투를 하고 쿠로미를 좋아하며 카카오톡 프로필을 온갖 쿠로미, 폼폼푸린으로 도배하며 마치 우울증에 걸린 듯 가짜 우울증을 호소하지 않는 여자",
      "한 시라도 가만히 있지를 않으며 주변 남자들이 여자라는 이유로 잘 이해해주니 마치 자기가 무엇이라도 된 것 마냥 뽕에 취해 남색과 향락을 밝혀 보는 이들의 눈이 찌뿌려지지 않게 하는 여자",
      "용협론에 대해 동의하는 여자",
    ] },
    { header: "연락처", body: [
      {title:"카카오톡", body:"?"},
      {title:"인스타그램", body:"?"},
      {title:"블로그", body:"?"},
      {title:"전화번호", body:"010-2837-2708"},
      {title:"이메일", body:"yhlee803@naver.com"},
  ] },
    // 나머지 페이지 데이터는 필요에 따라 추가할 수 있습니다.
  ];

  return (
    <Container>
      {pageList.map((item, index) => (
        <Page key={index} itemArr={item.body} header={item.header} pageIndex={index} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  background-color: #05122d;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

function Page({ itemArr, header, pageIndex }) {
  const [itemAnimation, setItemAnimation] = useState("stop");

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  if (inView && itemAnimation === "stop") {
    setItemAnimation("start");
  }

  return (
    <PageWrap ref={ref}>
      <Contents>
        <HeaderText>{header}</HeaderText>
        {itemArr &&
          itemArr.map((item, index) => (
            <Item
              key={index}
              initial={{ opacity: 0, y: 100 }}
              animate={itemAnimation === "start" ? { opacity: 1, y: 0 } : {}}
              transition={{
                type: "spring",
                stiffness: 30,
                delay: 0.5 * index,
              }}
            >
              {typeof item === "object" && (
                <>
                  {item.title && <ItemTitle>{item.title}</ItemTitle>}
                  {item.body && <ItemBody>{item.body}</ItemBody>}
                </>
              )}
              {typeof item === "string" && <div>{item}</div>}
            </Item>
          ))}
      </Contents>
    </PageWrap>
  );
}

const HeaderText = styled.h1`
  color: white;
  text-align: center;
`;

const Item = styled(motion.div)`
  text-align: center;
  margin-bottom: 20px;
  color: white;
`;


const ItemTitle = styled.div``;

const ItemBody = styled.div``;

const PageWrap = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
`;

const Contents = styled.div`
  width: 90%;
  height: 90%;
  position: relative;
`;
