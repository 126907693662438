import React from 'react';
import Main from './pages/Main';

const App = () => {
  return (
    <>
     <Main/> 
    </>
  );
};

export default App;